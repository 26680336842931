import { combineReducers } from 'redux';
import reduxType from './redux-type';
// 修改登录状态
function changeLogin(store,action){
    switch (action.type) {
        case reduxType.isLogin:
             return action.isLogin;
        default: return localStorage.getItem('isLogin') === 'true';
    }
}
// 修改统一平台登录状态
function changeLoginUnify(store,action){
    switch (action.type) {
        case reduxType.isLofinUnify:
             return action.isLogin;
        default: return localStorage.getItem('isLoginUnify') === 'true';
    }
}
// 修改个人信息数据
function changeUserData(store,action){
    switch (action.type) {
        case reduxType.userData:
            return action.userData;
        default: return {};
    }
}

export default combineReducers({
    changeLogin,
    changeLoginUnify,
    changeUserData
})