import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { HashRouter as Router} from "react-router-dom";
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import store from './redux/redux-store';
import App from './pages/App';
// import App from './pages/AppUnify';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';

ReactDOM.render(
	<ConfigProvider locale={zhCN}>
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
	</ConfigProvider>,
	document.getElementById('root')
);

serviceWorker.unregister();
