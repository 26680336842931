// const serviceName1 = '/base';
const serviceName1 = '/akbase';
const serviceName2 = '/tools';
const serviceName3 = '/export';

export const universalUpload = serviceName1 + '/universal/v1/upload'; // 更换设备详情
export const universalExtUpload = serviceName1 + '/universal/v1/ext/upload';  // 上传音频文件
export const universalRemove = serviceName1 + '/universal/v1/remove'; // 删除上传文件
export const universalCaptcha = serviceName1 + '/universal/v1/captcha/'; // 删除上传文件
/** 员工相关接口 */
export const emplFindRoles = serviceName1 + '/emp/v1/findRoles'; // 重新获取权限
export const emplLogin = serviceName1 + '/emp/v1/login'; // 用户登录
export const emplIslogin = serviceName1 + '/emp/isLogin'; // 判断是否登录
export const appletsGetUserInfo = serviceName1 + '/applets/ding/getUserInfo'; // 用户扫码登录
export const emplFindById = serviceName1 + '/emp/v1/findById/'; // 获取用户信息
export const emplUpdataById = serviceName1 + '/emp/v1/updateById'; // 更新用户信息
export const emplChangePass = serviceName1 + '/emp/v1/changePassword'; // 修改密码
export const emplLogout = serviceName1 + '/emp/v1/logout'; // 退出登录
export const emplFindAll = serviceName1 + '/emp/v1/findAll'; // 获取员工全部列表
export const emplFindByPageList = serviceName1 + '/emp/v1/findByPageList'; // 获取员工分页列表
export const emplDeleteByBatch = serviceName1 + '/emp/v1/deleteByBatch'; // 删除员工
export const emplAdd = serviceName1 + '/emp/v1/add'; // 新增员工
export const emplUpdateById = serviceName1 + '/emp/v1/updateById'; // 更新员工信息
/** 字典相关接口 */
export const dictFindByPageList = serviceName1 + '/dict/v1/findByPageList'; // 字典分页列表
export const dictDeleteByBatch = serviceName1 + '/dict/v1/deleteByBatch'; // 删除字典
export const dictFindById = serviceName1 + '/dict/v1/findById/'; // 查看字典详情
export const dictAdd = serviceName1 + '/dict/v1/add'; // 新增字典
export const dictUpdateById = serviceName1 + '/dict/v1/updateById'; // 更新字典
export const dictFindByAll = serviceName1 + '/dict/v1/findAll/'; //  获取全部字典列表
/** 部门相关接口 */
export const deptFindAll = serviceName1 + '/dept/v1/findAll'; // 获取全部部门列表
export const deptFindByPageList = serviceName1 + '/dept/v1/findByPageList'; // 获取部门分页列表
export const deptDeleteByBatch = serviceName1 + '/dept/v1/deleteByBatch'; // 删除部门
export const deptAdd = serviceName1 + '/dept/v1/add'; // 新增部门
export const deptUpdateById = serviceName1 + '/dept/v1/updateById'; // 更新部门
export const deptFindById = serviceName1 + '/dept/v1/findById/'; // 查看部门详情
export const deptTreeList = serviceName1 + '/dept/v1/treeList'; // 获取树列表
/** 菜单相关接口 */
export const menuFindAllDir = serviceName1 + '/menu/v1/findAllDir'; // 获取全部菜单列表
export const menuFindByPageList = serviceName1 + '/menu/v1/findByPageList'; // 获取菜单分页列表
export const menuDeleteByBatch = serviceName1 + '/menu/v1/deleteByBatch'; // 删除菜单
export const menuFindById = serviceName1 + '/menu/v1/findById/'; // 获取菜单详情
export const menuAdd = serviceName1 + '/menu/v1/add'; // 新增菜单
export const menuUpdateById = serviceName1 + '/menu/v1/updateById'; // 更新菜单
export const menuTreeList = serviceName1 + '/menu/v1/treeList'; // 获取菜单树
/** 角色相关接口 */
export const roleFindByPageList = serviceName1 + '/role/v1/findByPageList'; // 获取角色分页列表
export const roleDeleteByBatch = serviceName1 + '/role/v1/deleteByBatch'; // 删除角色
export const roleFindById = serviceName1 + '/role/v1/findById/'; // 查看角色详情
export const roleAdd = serviceName1 + '/role/v1/add'; // 增加角色
export const roleUpdateById = serviceName1 + '/role/v1/updateById'; // 更新角色
export const roleFindAll = serviceName1 + '/role/v1/findAll'; // 获取角色全部列表
/** 设备相关接口 */
export const equipmentFindBuPageList = serviceName1 + '/equipment/v1/findByPageList'; // 获取设备分页列表
export const equipmentAdd = serviceName1 + '/equipment/v1/add'; // 新增设备
export const equipmentUpdateById = serviceName1 + '/equipment/v2/updateById'; // 更新设备
export const equipmentFindById = serviceName1 + '/equipment/v1/findById/'; // 查看设备详情
export const equipmentDeleteByBatch = serviceName1 + '/equipment/v1/deleteByBatch'; // 删除设备
export const equipmentFindList = serviceName1 + '/equipment/v1/findList/'; // 获取设备分类
export const equipmentImports = serviceName1 + '/equipment/v1/imports'; // 导入设备文件
export const equiementImages = serviceName1 + '/equipment/v1/images'; // 上传设备图片
export const equipmentFindImageById = serviceName1 + '/equipment/v1/findImageById/'; // 设备图片地址
export const equipmentUpdateEquipmentDiagram = serviceName2 + '/equipment/v1/updateEquipmentDiagram'; // 提交设备图片
export const equipmentGetDept = serviceName2 + '/equipment/v1/getDept/'; // 获取单位部门列表
export const equipmentGetDevice = serviceName2 + '/equipment/v1/getDevice/'; // 获取装置类别
export const equipmentFindCategory = serviceName2 + '/equipment/v1/findCategory'; // 获取类型
/** 装置相关接口 */
export const deviceFindByPageList = serviceName1 + '/device/v1/findByPageList'; // 获取装置分页列表
export const deviceAdd = serviceName1 + '/device/v1/add'; // 新增装置
export const deviceUpdateById = serviceName1 + '/device/v1/updateById'; // 更新装置
export const deviceFindById = serviceName1 + '/device/v1/findById/'; // 获取装置详情
export const deviceDeleteByBatch = serviceName1 + '/device/v1/deleteByBatch'; // 删除装置
// export const deviceFindList = serviceName1 + '/device/v1/findList'; // 获取全部装置列表
export const deviceFindAll = serviceName1 + '/device/v1/findAll/'; // 根据id获取全部装置列表
/** 组织结构相关接口 */
export const orgFindByPageList = serviceName1 + '/org/v1/findByPageList'; // 获取部门单位分页列表
export const orgAdd = serviceName1 + '/org/v1/add'; // 新增单位部门
export const orgUpdateById = serviceName1 + '/org/v1/updateById'; // 更新单位部门
export const orgFindById = serviceName1 + '/org/v1/findById/'; // 获取单位部门详情
export const orgDeleteByBatch = serviceName1 + '/org/v1/deleteByBatch'; // 删除部门单位
export const orgFindAll = serviceName1 + '/org/v1/findAll/' // 根据id获取单位部门列表
/** 用户相关接口 */
export const userFindByPageList = serviceName1 + '/user/v1/findByPageList'; // 获取用户分页列表
export const userAdd = serviceName1 + '/user/v1/add'; // 新增用户
export const userUpdateById = serviceName1 + '/user/v1/updateById'; // 更新用户
export const userFindById = serviceName1 + '/user/v1/findById/'; // 查看用户详情
export const userDeleteByBatch = serviceName1 + '/user/v1/deleteByBatch'; // 删除用户
/** 案例相关接口 */
export const caseFindByPageList = serviceName1 + '/case/v1/findByPageList'; // 获取案例分页列表
export const caseAdd = serviceName1 + '/case/v1/add'; // 新增案例
export const caseUpdateById = serviceName1 + '/case/v1/updateById'; // 更新案例
export const caseFindById = serviceName1 + '/case/v1/findById/'; // 获取案例详情
export const caseDeleteByBatch = serviceName1 + '/case/v1/deleteByBatch'; // 删除案例
export const csaeInit = serviceName1 + '/case/v1/init/'; // 新增案例获取文档地址
/** 腐蚀调查工具 */
// export const deadlineFindByPageList = serviceName2 + '/deadline/v1/findByPageList'; // 获取腐蚀调查工具分页列表
export const deadlineFindByPageList = serviceName2 + '/deadline/v2/findByPageList'; // 获取腐蚀调查工具分页列表
export const deadlineGetId = serviceName2 + '/deadline/v1/getId'; // 生成本年度报告
export const deadlineCommentFindByDeadlineId = serviceName2 + '/deadlineComment/v1/findByDeadlineId/'; // 获取腐蚀调查工具评论详情
export const deadlineCommentAdd = serviceName2 + '/deadlineComment/v1/add'; // 新增评论
export const deadlineFindById = serviceName2 + '/deadline/v1/findById/'; // 获取腐蚀调查工具的详情
export const deadlineStaging = serviceName2 + '/deadline/v1/staging/'; // 请求对比周期
export const deadlineCompare = serviceName2 + '/deadline/v1/compare'; // 获取对比周期详情 (换热器)
export const deadlineFindByDeadline = serviceName2 + '/deadline/v1/findByDeadline/'; // 获取设备调查相关数据
export const deadlineCollection = serviceName2 + '/deadline/v1/collection/'; // 分段提交设备调查数据
export const deadlineExportList = serviceName2 + '/deadline/v1/exportList/'; // 导出报告列表
export const deadlineFindCommentOrgList = serviceName2 + '/deadline/v1/findCommentOrgList'; // 今日已评价的单位列表
export const deadlineGetCycleList = serviceName2 + '/deadline/v1/getCycleList'; // 获取周期数
// export const deadlineExports = serviceName2 + '/deadline/v1/exports'; // 导出报告
export const deadlineExports = serviceName2 + '/deadline/v2/exports'; // 导出报告
export const deadlineExportsComment = serviceName2 + '/deadline/v1/exportsComment/'; // 导出评价表
export const deadlineEquipmentCommentExport = serviceName2 + '/deadline/v1/equipmentCommentExport'; // 导出今日设备评论报告
export const containerFindById = serviceName2 + '/container/v1/findById'; // 获取容器详情
export const containerSaveOrUpdate = serviceName2 + '/container/v1/saveOrUpdate/'; // 容器更新评论|新增或修改采集数据 comment: 评论 survey: 其他数据
export const containerFindCommentByDeadlineId = serviceName2 + '/container/v1/findCommentByDeadlineId'; // 获取容器评论详情
/** 大修相关 */
export const repairFindByPageList = serviceName2 + '/repair/v1/findByPageList'; // 大检修首页数据分页查询
export const reqairChangeStatus = serviceName2 + '/repair/v1/changeStatus'; // 开启或者结束周期
export const repairSubmit = serviceName2 + '/repair/v1/submit'; // 日报提交
export const reqairCheck = serviceName2 + '/repair/v1/check'; // 导入单位
export const reqairCheckList = serviceName2 + '/repair/v1/checkList'; // 查询未被添加的单位
export const repairFindDaily = serviceName2 + '/repair/v1/findDaily'; // 查询当日日报id
export const repairSave = serviceName2 + '/repair/v1/save/'; // 采集任务的分段提交
export const repairFindByList = serviceName2 + '/repair/v1/findByList/'; // 获取分段提交中的子列表
export const repairFindDetail = serviceName2 + '/repair/v1/findDetail/'; // 获取分段提交的详情
export const reqairFindDeviceList = serviceName2 + '/repair/v1/findDeviceList'; // 获取单位下所有的装置
export const reqairFindEquipmentList = serviceName2 + '/repair/v1/findEquipmentList'; // 获取装置下的所有设备
export const repairDeleteById = serviceName2 + '/repair/v1/deleteById/'; // 删除子项列表中的数据
export const reqairExport = serviceName2 + '/repair/v1/export/'; // 导出日报
export const overhaulByOrgId = serviceName3 + '/overhaul/v1/byOrgId'; // 导出日报
export const reqairFindById = serviceName2 + '/repair/v1/findById/'; // 获取单条大修数据
export const repairJobOverviewItems = serviceName2 + '/jobOverviewItems'; // 工作概况相关数据
export const repairJobOverUpData = serviceName2 + '/jobOverviewItems/update'; // 更新工作概况数据
export const repairJobOverList = serviceName2 + '/jobOverviewItems/list'; // 获取工作概况列表
export const repairWorkOverviewItems = serviceName2 + '/workOverviewItems'; // 明日工作计划相关数据
export const repairWorkOverUpData = serviceName2 + '/workOverviewItems/update'; // 更新明日工作计划数据
export const repairWorkOverList = serviceName2 + '/workOverviewItems/list'; // 获取明日工作计划列表
export const repairMyself = serviceName2 + '/repair/submit/myself'; // 提交日志
/** 项目总览 */
export const projectFindByPageList = serviceName1 + '/project/v1/findByPageList'; // 项目总览数据
export const projectAdd = serviceName1 + '/project/v1/add'; // 新增项目
export const projectUpdateById = serviceName1 + '/project/v1/updateById'; // 更新项目
export const projectFIndById = serviceName1 + '/project/v1/findById'; // 获取项目详情
export const projectDeleteByBatch = serviceName1 + '/project/v1/deleteByBatch'; // 删除项目
export const projectFindAll = serviceName1 + '/project/v1/findAll'; // 获取全部项目
export const projectGetDict = serviceName1 + '/project/v1/getDict'; // 获取专业和基础定级
export const projectFindProjectLeaderList = serviceName1 + '/project/v1/findProjectLeaderList'; // 查询项目经理
export const projectFindProjectEmpList = serviceName1 + '/project/v1/findProjectEmpList'; // 查询项目组员
export const projectFindEmployeeListByProjectId = serviceName1 + '/project/v1/findEmployeeListByProjectId?projectId='; // 通过项目级联查询专家
export const projectFindJoinProjectByEmployeeId = serviceName1 + '/project/v1/findJoinProjectByEmployeeId'; // 专家参与过的项目
export const evaluationFindList = serviceName1 + '/evaluation/v1/findList'; // 查询对应模块可搜索的专家列表
/** 专家管理 */
export const professionFindProfessionByPageList = serviceName1 + '/emp/profession/v1/findProfessionByPageList'; // 专家分页
export const professionFindById = serviceName1 + '/emp/profession/v1/findById'; // 查看专家
export const professionUpdate = serviceName1 + '/emp/profession/v1/update/'; // 更新专家
export const professionFindEmployeeListByName = serviceName1 + '/emp/profession/v1/findEmployeeListByName'; // 通过名称搜索
export const professionFindEmployeeListByNumberNo = serviceName1 + '/emp/profession/v1/findEmployeeListByNumberNo'; // 通过专家编号搜索
export const professionDeleteEmpByProjectId = serviceName1 + '/emp/profession/v1/deleteEmpByProjectId'; // 删除专家和项目的关联关系
export const professionFindProfessionals = serviceName1 + '/emp/profession/v1/findProfessionals'; // 查询所有的专家
export const professionFindUserListByRoleids = serviceName1 + '/emp/profession/v1/findUserListByRoleids?roleIds='; // 通过角色id查询拥有该角色的用户id
/** 报告管理 */
export const reportFindByPageList = serviceName1 + '/project/report/v1/findByPageList'; // 报告总览
export const reportFindById = serviceName1 + '/project/report/v1/findById'; // 查看报告详情
export const reportAdd = serviceName1 + '/project/report/v1/add'; // 新增报告
export const reportUpdateById = serviceName1 + '/project/report/v1/updateById'; // 更新报告
export const reportDeleteByBatch = serviceName1 + '/project/report/v1/deleteByBatch'; // 删除报告
export const reportFindJoinProjectReportByEmployeeId = serviceName1 + '/project/report/v1/findJoinProjectReportByEmployeeId'; // 专家撰写过的报告
/** 评估管理 */
export const expertFindModelTypeList = serviceName1 + '/expert/model/v1/findModelTypeList'; // 评估模型分类
export const expertFindByPageList = serviceName1 + '/expert/model/v1/findByPageList'; // 评估模型分页查询
export const expertFindById = serviceName1 + '/expert/model/v1/findById'; // 评估模型详情
export const expertActivation = serviceName1 + '/expert/model/v1/activation'; // 激活评估模型
export const expertAdd = serviceName1 + '/expert/model/v1/add'; // 新增评估模型
export const expertUpdateById = serviceName1 + '/expert/model/v1/updateById'; // 更新评估模型
export const modelFindByCategoryId = serviceName1 + '/model/auth/v1/findByCategoryId'; // 查询分类权限
// export const modelAdd = serviceName1 + '/model/auth/v1/add'; // 新增分类权限
// export const modelUpdateById = serviceName1 + '/model/auth/v1/updateById'; // 更新分类权限
export const modelSaveOrUpdate = serviceName1 + '/model/auth/v1/saveOrUpdate'; // 新增或修改分类权限
export const evaluationFindActiveModelByCategoryId = serviceName1 + '/evaluation/v1/findActiveModelByCategoryId'; // 开始评估
export const evaluationAdd = serviceName1 + '/evaluation/v1/add'; // 提交评估
export const evaluationUpdateById = serviceName1 + '/evaluation/v1/updateById'; // 修改评估
export const evaluationFindByPageList = serviceName1 + '/evaluation/v1/findByPageList'; // 查看评估列表
export const evaluationFindById = serviceName1 + '/evaluation/v1/findById'; // 查询评估表单详情
/** 专家评级结果 */
export const modelPost = serviceName1 + '/expert/model/v1/post'; // 查询所有岗位
export const avgFindByPageList = serviceName1 + '/avg/v1/findByPageList'; // 专家评估结果总览分页查询
export const avgQuickRating = serviceName1 + '/avg/v1/quickRating'; // 专家评估结果总览分页查询
export const avgFindEvaluationInfo = serviceName1 + '/avg/v1/findEvaluationInfo'; // 查看表单详情

// 腐蚀调查相关接口
export const collectionInit = serviceName2 + '/equipment/init'; // 获取腐蚀调查列表
export const collectionFindByPageList = serviceName2 + '/equipment/findByPageList'; // 获取腐蚀调查列表
export const collectionFindById = serviceName2 + '/equipment/findById/'; // 查询设备详情
export const collectionGetJsonByEquipmentId = serviceName2 + '/equipment/getJsonByEquipmentId/'; // 获取腐蚀调查数据
export const collectionSubmit = serviceName2 + '/daily/submit/'; // 腐蚀调查提交数据
export const collectionOrgFindList = serviceName2 + '/org/findList'; // 查询单位和部门列表
export const collectionDeviceFindList = serviceName2 + '/device/findList'; // 获取装置列表
export const collectionCommentFindByDailyId = serviceName2 + '/comment/findByDailyId/'; // 查询评论
export const collectionkeywordAll = serviceName2 + '/dict/keyword/findAll'; // 获取缺陷项的建议
export const collectionCommentSubmit = serviceName2 + '/comment/submit'; // 提交评论
export const collectionCompare = serviceName2 + '/daily/compare'; // 比较
export const collectionFindByToday = serviceName2 + '/daily/findByToday'; // 查询今日完成评价的单位
export const collectionGetDeptListByOrgId = serviceName2 + '/daily/getDeptListByOrgId/'; // 查询今日完成评价的单位
export const collectionGetDeviceListByDeptId = serviceName2 + '/daily/getDeviceListByDeptId/'; // 查询今日完成评价的部门
export const collectionFindTodayByOrgId = serviceName2 + '/daily/findTodayByOrgId/'; // 根据单位id查询今日评价的设备
export const collectionFindTodayByDeviceId = serviceName2 + '/daily/findTodayByDeviceId/'; // 根据单位id查询今日评价的设备
export const collectionStaging = serviceName2 + '/daily/staging/'; // 获取对比周期
export const exportDaily = serviceName3 + '/corrosion/exportDaily/'; // 导出今日报告
export const exportExcel = serviceName3 + '/corrosion/exportExcel/'; // 批量导出评价表
export const exportComment = serviceName3 + '/corrosion/exportComment/'; // 导出日报
export const exportCommentAll = serviceName3 + '/corrosion/exportCommentAll'; // 导出当日评价表
export const batchCheckout = serviceName3 + '/corrosion/batch/checkout'; // 打包下载图片
export const corrosionExportImgs = serviceName3 + '/corrosion/exportImgs'; // 批量打包下载图片