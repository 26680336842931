/*
 * @Author: cuikaiqiang
 * @Date: 2021-08-23 17:11:03
 * @LastEditors: cuikaiqiang
 * @LastEditTime: 2022-04-07 17:41:36
 * @FilePath: /my-app/src/pages/App.js
 * @Description: 
 */
import '@/scss/basicPage/app.scss';
import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from "react-router-dom";
import lazyLoad from '@/js/lazyLoad';
import dataServer from '../js/dataServer';
import { emplIslogin } from '@/js/dataServerUrl';
const Login = lazyLoad(() => import('@p/basicPage/Login')); // 安恪系统登录页面
const MainPag = lazyLoad(() => import('@p/basicPage/MainPage')); // 安恪系统主页
// const Login = lazyLoad(() => import('@p/basicPage/LoginRuoyi')); // 若依版安恪系统登录页面
// const MainPag = lazyLoad(() => import('@p/basicPage/MainPageRuoyi')); // 若依版安恪系统主页

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	UNSAFE_componentWillMount() {}
	componentDidMount() {
		this.init();
	}
	// 初始化方法
	init = () => {
		// 判断是否已经登录了 如果没有登录则跳转登录页面 否则跳转首页
		if (localStorage.getItem('isLogin') !== 'true') {
			let winHref = window.location.href;
			this.props.history.push({
				pathname: '/login',
				state: { data: winHref }
			})
		}else{
			this.judgeLogin();
		}
	}
	// 判断是否登录
	judgeLogin = () => {
		dataServer.get({
			url: emplIslogin,
			data: {},
			dispose: (res) => {
				if(this.props.history.location.pathname === '/'){
					this.props.history.push('/home')
				}
			}
		})
	}
	render() {
		return (
			<>
				{/* 此部分为安恪后台管理系统 */}
				{
					this.props.changeLogin ?
					<MainPag></MainPag> :
					<Switch>
						<Route exact path='/' component={Login}></Route>
						<Route path='/login' component={Login}></Route>
					</Switch>
				}
			</>
		)
	};
}
export default withRouter(connect((state) => { return state; },)(App));
