import axios from 'axios';
import { message,Modal } from 'antd';

// 在响应后需要做的事情
axios.interceptors.response.use(
    res => {
        disposeCode(Number(res.data.code))
        return res;
    },
    err => {
        if(err.response){
            let code = Number(err.response.status);
            disposeCode(code)
        }else{
            return err
        }
    }
);
// 处理code值页面
const disposeCode = (code) => {
    if(code === 5001){
        let rememberPasswordList = localStorage.getItem('rememberPasswordList') || [];
        localStorage.clear();
        localStorage.setItem('rememberPasswordList', rememberPasswordList)
        window.location.reload();
        // get(emplFindRoles, {}, (res) => {
        //     localStorage.setItem('isLogin',true);
        //     localStorage.setItem('userData',JSON.stringify(res.user));
        //     localStorage.setItem('menus',JSON.stringify(res.menus));
        //     localStorage.setItem('token',res.token);
        //     localStorage.setItem('scope',res.scope);
        //     localStorage.setItem('authorities',JSON.stringify(res.authorities));
        //     Modal.confirm({
        //         title: '提示',
        //         content: '您的权限已发生变化，请刷新页面后重试',
        //         cancelText: '稍后',
        //         onOk(){
        //             window.location.reload();
        //         }
        //     })
        // })
    }
    if(code === 504 || code === 4002){
        let rememberPasswordList = localStorage.getItem('rememberPasswordList') || [];
        localStorage.clear();
        localStorage.setItem('rememberPasswordList', rememberPasswordList)
        window.location.reload();
    }
    if(code === 500){
        Modal.error({
            title: code,
            content: '服务器发生错误，请联系管理员处理'
        })
    }
}
// 获取请求接口的通用配置
const getConfig = () => {
    return {
        baseURL: baseURL, //请求IP地址
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'scope': localStorage.getItem('scope')
        }, // 自定义请求头
        timeout: '0',// 请求超时时间 0为不限制请求时间
        withCredentials: true, // 表示跨域请求时是否需要凭证
        responseType: 'json', // 表示服务器响应类型
        data: {}, // 作为请求主体被发送数据 仅适用于 PUT、POST、PATCH
        params: {}, // 与URL一块发送的数据 
        transformRequest: function(data){ // 请求前可以修改的数据
            // 判断是否为上传文件
            if (Object.prototype.toString.call(data) !== '[object FormData]') {
                return JSON.stringify(data);
            }else{
                return data;
            }
        },
        transformResponse: function(data){ // 请求返回数据
            return data;
        }
    }
}
// get请求方式
const get = (obj) =>{
    requestFun(obj, 'get');
};
// post请求方式
const post = (obj) => {
    requestFun(obj, 'post');
}
// delete请求
const del = (obj) => {
    requestFun(obj, 'delete');
}
const put = (obj) => {
    requestFun(obj, 'put')
}
// 进行数据请求 (obj: {url: 接口地址, baseUrl: 请求网址, data: 请求参数, dispose: 成功方法, error: 错误方法})
const requestFun = (obj, method,) => {
    let config = getConfig();
    config.method = method;
    // 判断是否存在baseURL 如果存在则将通用的替换为专用的
    if(obj.baseUrl){
        config.baseURL = obj.baseUrl
    }
    // 判断缓存中是否存在token
    if(localStorage.getItem('token')){
        config.headers['token'] = localStorage.getItem('token');
        // config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }
    config.url = obj.url;
    // 判断当前是什么请求类型 不同的请求类型传递不同的参数类型
    let dataParam = ['post', 'put'];
    if(dataParam.includes(method)){
        config.data = obj.data;
        config.params = {};
    }else{
        config.params = obj.data;
        config.data = {};
    }
    axios(config).then((response) => {
        if(response){
            disposeFun(response.data, obj);
        }
    }).catch(err => {})
}
// 请求结果进行初步处理
const disposeFun = (data, obj) => {
    // 判断是否为请求正确的code
    if(data.code === 0 || data.code === 200){
        if(!data.data && !data.extra){
            data = {}
        }
        if(typeof obj.dispose == 'function'){
            obj.dispose(data.data,data.extra)
        }
    }else{
        if(obj.error || typeof obj.error == 'function'){
            obj.error(data.msg)
        }else{
            message.error(data.msg)
        }
    }
}
// const AppId = 'dingoaopwdkik6ntfhyszz'; // 测试扫码
const AppId = 'dingoaohmiemuq8rolrirh'; // 正式扫码
// const baseURL = 'http://192.168.1.10:8888'; // 本地地址
// const baseURL = 'http://api.anchoremc.com/test'; // 测试接口
const baseURL = 'http://pro.anchoremc.com'; // 正式接口
const baseURLRouYi = 'http://api.anchoremc.com/test/system'; // 若依后台地址

const dataServer = {
    baseURL,
    baseURLRouYi,
    AppId,
    post,
    get,
    del,
    put,
}
export default dataServer;